import "@css/app.css";

import Alpine from 'alpinejs';

window.Alpine = Alpine;

document.addEventListener('alpine:init', () => {
  Alpine.data('membershipPricing', (apiUrl) => ({
    size: false,
    package: false,
    register: false,
    init() {
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.$refs.MEMSPPLUS.innerText = Math.trunc(data.MEMSPPLUS);
          this.$refs.MEMSPPLAT.innerText = Math.trunc(data.MEMSPPLAT);
          this.$refs.MEM25PLUS.innerText = Math.trunc(data.MEM25PLUS);
          this.$refs.MEM25PLAT.innerText = Math.trunc(data.MEM25PLAT);
          this.$refs.MEM69PLUS.innerText = Math.trunc(data.MEM69PLUS);
          this.$refs.MEM69PLAT.innerText = Math.trunc(data.MEM69PLAT);
        });
    }
  }));
});

Alpine.start();

if ($('.smooth-scroll')) {
  var $root = $('html, body');

  $('a[href^="#"]').click(function() {
      var href = $.attr(this, 'href');

      if ($('.anchor-nav')) {
        $root.animate({scrollTop: $(href).offset().top -136}, 'slow', function () {
            window.location.hash = href;
        });
      } else if ($('.jump-to')) {
        $root.animate({scrollTop: $(href).offset().top -88}, 'slow', function () {
            window.location.hash = href;
        });
      }

      return false;
  });
}

if ($('.banner-items')) {
  $(".banner-items").slick( {
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
      dots: true,
      appendDots: '.home-banner-slide-controls',
      speed: 500,
      fade: true,
      cssEase: 'linear',
  });
  $('.banner-items').show();
}

if ($('.testimonial-slider')) {
  $(".testimonial-slider").slick( {
      arrows: true,
      autoplay: false,
      autoplaySpeed: 4000,
      infinite: true,
      dots: true,
      speed: 500,
      adaptiveHeight: true,
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
          }
        },
      ]
  });
  $('.testimonial-slider').show();
}

if ($('.webinar-slider-wrap')) {
  $('.webinar-slider-wrap').each(function(index, sliderWrap) {
    var $slider = $(sliderWrap).find('.webinar-slider');
    var $next = $(sliderWrap).find('.next-web');
    var $prev = $(sliderWrap).find('.prev-web');

    $slider.slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: $next,
      prevArrow: $prev,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: false,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
          }
        }
      ]
    });
  });
}

// Social Share
var shareLink = document.URL;
var shareTitle = document.title;
var windowFeatures = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600';

// Facebook
$('.share-facebook').click(function(e){
  window.open(("https://www.facebook.com/sharer/sharer.php?u="+ shareLink + ""), 'Facebook', windowFeatures);
});

// Twitter
$('.share-twitter').click(function(e) {
  window.open(("https://twitter.com/intent/tweet?url=" + shareLink + ""), 'Twitter', windowFeatures);
});

// Linked In
$('.share-linkedin').click(function(e){
  window.open(("https://www.linkedin.com/shareArticle?url=" + shareLink + ""), 'LinkedIn', windowFeatures);
});

$('#filter-reset').click(function(e) {
  window.location.href = window.location.href.split('?')[0];
});

$('#filter-mobile-reset').click(function(e) {
  window.location.href = window.location.href.split('?')[0];
});

$("#typeReset").click(function(e) {
  $("#typeButtonReset").empty();
});

$("#speakerReset").click(function(e) {
  $("#speakerButtonReset").empty();
});

$("#topicReset").click(function(e) {
  $("#topicButtonReset").empty();
});

$("#jurisdictionReset").click(function(e) {
  $("#jurisdictionButtonReset").empty();
});

$("#sortByReset").click(function(e) {
  $("#sortByButtonReset").empty();
});

// Passive event listeners
jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};
